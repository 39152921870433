.navbar {
    background-color: $navbar-bg; // Set the background color
    box-shadow: $navbar-box-shadow; // Apply the box shadow

  }
  .nav-tabs .nav-link.active {
    background-color: #9f1521; /* Set the background color of the selected tab */
    color: #ffffff; /* Set the text color of the selected tab */
    // border-color: red; /* Optional: Set the border color */
    border-radius: 0;
  }
  