
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap');

// Bootstrap mixins and functions
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";

// Change variables here
@import "dashboard/variables";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// dashboard mixins & functions
@import "dashboard/functions";


// Components
@import "dashboard/components";