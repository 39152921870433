.footer {
    background-color: #000; // Optional: Set the background color
    padding: 1rem 0; // Optional: Adjust padding as needed
  
    .footer-container {
      width: 75%;
      margin: 0 auto; // Center the container horizontally
  
      .container-fluid {
        padding-left: 0;
        padding-right: 0;
      }
    }
  
    .footer-text {
      font-weight: 300;
  
      // Default font size
      font-size: 0.85rem;
  
      // Media query for extra-small screens
      @media (max-width: 576px) { 
        font-size: 0.65rem; // Smaller font size for xs screens
      }
  
      // Media query for small screens and up
      @media (min-width: 577px) and (max-width: 768px) { 
        font-size: 0.75rem; // Adjust font size for sm screens if needed
      }
    }
  }
  