.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

main {
  flex-grow: 1;
}

.group-add-button {
  display: block;
  width: 100%;
}

.list-item-wrapper {
  text-align: center;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.list-action-button {
  box-shadow: none;
}

.list-item-wrapper :hover {
  color: #c12424;
}

.footer {
  background-color: #282323;
  margin-top: 16px;
}

.add-camera-entry-modal-dialog {
  height: 95vh !important;
  max-height: 95vh !important;
  max-width: 100%; /* Remove the max-width constraint */
  width: 100%; /* Ensure it takes the full width */
  margin: 0; /* Remove default margins */
  & .modal-dialog {
    width: 60vw !important;
    max-width: 60vw !important;
}
}

@media (max-width: 768px) {
  .add-camera-entry-modal-dialog {
    width: 100vw !important;
    max-width: 100vw  !important; /* Set a max-width for larger screens */
    & .modal-dialog {
      width: 96vw !important;
      max-width: 96vw !important;
  }}
}

.modal-title {
  font-size: 1.5rem; /* Default size for larger screens */
}
/* Smaller font size for iPad screens (up to 768px) */
@media (max-width: 868px) {
  .modal-title {
    font-size: 1.25rem;
  }
}

/* Additional adjustment for smaller mobile devices */
@media (max-width: 576px) {
  .modal-title {
    font-size: 1rem; /* Even smaller size for mobile */
  }
}



.small-device-text {
  font-size: 0.875rem; /* Base size for mobile devices */
}

@media (min-width: 768px) {
  .small-device-text {
    font-size: 1rem; /* Medium screen size (e.g., tablets) */
  }
}



.required::after {
  content: "*";
  color: rgb(165, 44, 43);
}

@media (min-width: 992px) {
  .jumbotron-image {
    background-position: top center;
    background-size: cover;
  }
}

@media (max-width: 992px) {
  .jumbotron-image {
    background-position: 70% 0%;
    background-size: cover;
  }
}

.jumbotron-image {
  position: relative;
  height: 60vh;
  background-size: cover;
  background-image: url(../../../assets/images/Koala.jpeg);
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px; 
  background-color: rgba(255, 0, 0, 0.5); 
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.jumbotron-title {
  margin-bottom: 10px;
  color: white;
}

.red-strip {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  background-color: $primary;
}

.privacy-plan {
  background-color: hsla(198, 100%, 20%, 0.07);
}
